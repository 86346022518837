import ability from "./ability";

export function setAbilities(access_map) {
  // console.log(access_map, "access_map");
  const ability_arr = [
    {
      action: "read",
      subject: "home",
    },
  ];
  if (access_map && access_map.length) {
    for (let i = 0; i < access_map.length; i++) {
      const map = access_map[i];

      if (map.action.length) {
        ability_arr.push({
          action: "read",
          subject: map.subject,
          permission: map.action,
          name: map.name,
        });
      }

      // if (map.delete) {
      //   ability_arr.push({
      //     action: "delete",
      //     subject: map.module_name,
      //   });
      // }
    }
    sessionStorage.setItem("userData", JSON.stringify(ability_arr));
    ability.update(ability_arr);
  } else {
    sessionStorage.setItem("userData", "");
  }
}
