import { setAbilities } from "@/libs/acl/setAbilities";
import { GetUserListService } from "@/apiServices/MasterServices";
import store from "@/store";

export default {
  namespaced: true,
  state: {
    user_authenticated: false,
    user_details: {
      name: "Firstname",
      email: "Lastname",
      role_name: "User",
    },
    new_notifications: [],
    new_notifications_count: 0,
  },
  getters: {
    getUserDetails: (state) => state.user_details,
    getUserAuthenticated: (state) => state.user_authenticated,
    getNewNotifications: (state) => state.new_notifications,
  },
  mutations: {
    SET_USER_AUTHENTICATED(state, val) {
      state.user_authenticated = val;
    },
    SET_USER_DETAILS(state, val) {
      state.user_details = val;
    },
    SET_NEW_NOTIFICATIONS(state, val) {
      state.new_notifications = val;
    },
    SET_NEW_NOTIFICATIONS_COUNT(state, val) {
      state.new_notifications_count = val;
    },
  },
  actions: {
    setUserDetails: async ({ dispatch, commit, state }, payload) => {
      store.commit("appConfig/UPDATE_SKIN", "light");
      await GetUserListService({ getUserPermission: "Y" })
        .then(async (response) => {
          if (response.data.status) {
            if (response.data.Records && response.data.Records.data.length) {
              await commit("SET_USER_DETAILS", response.data.Records.data[0]);
              if (!response.data.Records.data[0].org_name) {
                store.commit("appConfig/UPDATE_SKIN", "semi-dark");
              } else {
                store.commit("appConfig/UPDATE_SKIN", "light");
              }
              await commit("SET_USER_AUTHENTICATED", true);
              sessionStorage.setItem(
                "userOrg",
                response.data.Records.data[0].org_name
              );
              let parsedData = JSON.parse(
                response.data.Records.data[0].role_permission
              );
              setAbilities(parsedData);
            } else {
              sessionStorage.setItem("userData", "");
            }
          } else {
            sessionStorage.setItem("userData", "");
          }
        })
        .catch((err) => {
          sessionStorage.setItem("userData", "");
          console.log("Error in getting user profile ", err);
        });
    },
    setNewNotifications: async ({ dispatch, commit, state }, payload) => {
      MasterService.getNewNotifications()
        .then(async (response) => {
          if (response.data.status) {
            const arr = response.data.data.concat(state.new_notifications);
            await commit(
              "SET_NEW_NOTIFICATIONS_COUNT",
              state.new_notifications_count + response.data.data.length
            );
            await commit("SET_NEW_NOTIFICATIONS", arr);
          }
        })
        .catch((err) => {
          console.log("Error in setting new notifications ", err);
        });
    },
  },
};
