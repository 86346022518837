import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "login",
      component: () => import("@/views/pages/Authentication/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/pages/Home/Home.vue"),
      meta: {
        pageTitle: "Dashboard",
      },
    },
    {
      path: "/countrymaster",
      name: "countrymaster",
      component: () => import("@/views/pages/Masters/CountryMaster.vue"),
      meta: {
        pageTitle: "Country",
      },
    },
    {
      path: "/citymaster",
      name: "citymaster",
      component: () => import("@/views/pages/Masters/CityMaster.vue"),
      meta: {
        pageTitle: "City",
      },
    },
    {
      path: "/cinemamaster",
      name: "cinemamaster",
      component: () => import("@/views/pages/Masters/CinemaMaster.vue"),
      meta: {
        pageTitle: "Cinema/Theatre",
      },
    },
    {
      path: "/cinemaform",
      name: "cinemaform",
      component: () => import("@/views/pages/Masters/CinemaForm.vue"),
      meta: {
        pageTitle: "Cinema/Theatre",
      },
    },

    {
      path: "/seattypemaster",
      name: "seattypemaster",
      component: () => import("@/views/pages/Masters/SeatTypeMaster.vue"),
      meta: {
        pageTitle: "Seat Types",
      },
    },
    {
      path: "/languagemaster",
      name: "languagemaster",
      component: () => import("@/views/pages/Masters/LanguageMaster.vue"),
      meta: {
        pageTitle: "Language",
      },
    },
    {
      path: "/genremaster",
      name: "genremaster",
      component: () => import("@/views/pages/Masters/GenreMaster.vue"),
      meta: {
        pageTitle: "Genre",
      },
    },
    {
      path: "/currencymaster",
      name: "currencymaster",
      component: () => import("@/views/pages/Masters/CurrencyMaster.vue"),
      meta: {
        pageTitle: "Currency Master",
      },
    },

    {
      path: "/eventlist",
      name: "eventlist",
      component: () => import("@/views/pages/Event/EventList.vue"),
      meta: {
        pageTitle: "Event List",
      },
    },

    {
      path: "/movielist",
      name: "movielist",
      component: () => import("@/views/pages/Event/EventList.vue"),
      meta: {
        pageTitle: "Movie List",
      },
    },

    {
      path: "/eventOtherInfo",
      name: "eventOtherInfo",
      component: () => import("@/views/pages/Event/EventOtherInfo.vue"),
      meta: {
        pageTitle: "Speaker,Sponsers,Organisers",
      },
    },

    {
      path: "/eventform",
      name: "eventform",
      component: () => import("@/views/pages/Event/EventForm.vue"),
      meta: {
        pageTitle: "Event Form",
      },
    },
    {
      path: "/movieform",
      name: "movieform",
      component: () => import("@/views/pages/Event/EventForm.vue"),
      meta: {
        pageTitle: "Movie Form",
      },
    },

    {
      path: "/eventseatlayout",
      name: "eventseatlayout",
      component: () => import("@/views/pages/Event/EventSeatLayout.vue"),
      meta: {
        pageTitle: "Event Seat Layout",
      },
    },

    {
      path: "/EventSeatLayoutForWebsite",
      name: "EventSeatLayoutForWebsite",
      component: () =>
        import("@/views/pages/Event/EventSeatLayoutForWebsite.vue"),
      meta: {
        pageTitle: "Event Seat Layout",
      },
    },

    {
      path: "/eventbanners",
      name: "eventbanners",
      component: () => import("@/views/pages/Event/EventBanners.vue"),
      meta: {
        pageTitle: "Event Banners",
      },
    },

    {
      path: "/moviebanners",
      name: "moviebanners",
      component: () => import("@/views/pages/Event/EventBanners.vue"),
      meta: {
        pageTitle: "Movie Banners",
      },
    },
    {
      path: "/transactionreport",
      name: "transactionreport",
      component: () => import("@/views/pages/Report/TransactionReport.vue"),
      meta: {
        pageTitle: "Transaction Report",
      },
    },
    {
      path: "/reservationreport",
      name: "reservationreport",
      component: () => import("@/views/pages/Report/ReservationReport.vue"),
      meta: {
        pageTitle: "Reservation Report",
      },
    },

    {
      path: "/userlist",
      name: "userlist",
      component: () => import("@/views/pages/Users/UserList.vue"),
      meta: {
        pageTitle: "User List",
      },
    },
    {
      path: "/roleList",
      name: "roleList",
      component: () => import("@/views/pages/Users/RoleList.vue"),
      meta: {
        pageTitle: "Roles List",
      },
    },

    {
      path: "/userform",
      name: "userform",
      component: () => import("@/views/pages/Users/UserForm.vue"),
      meta: {
        pageTitle: "User Form",
      },
    },

    {
      path: "/voucherform",
      name: "voucherform",
      component: () => import("@/views/pages/Vouchers/VoucherForm.vue"),
      meta: {
        pageTitle: "Voucher Form",
      },
    },
    {
      path: "/voucherlist",
      name: "voucherlist",
      component: () => import("@/views/pages/Vouchers/VoucherList.vue"),
      meta: {
        pageTitle: "Voucher List",
      },
    },

    {
      path: "/organizationlist",
      name: "organizationlist",
      component: () =>
        import("@/views/pages/Organization/OrganizationList.vue"),
      meta: {
        pageTitle: "Organization List",
      },
    },
    {
      path: "/organizationform",
      name: "organizationform",
      component: () =>
        import("@/views/pages/Organization/OrganizationForm.vue"),
      meta: {
        pageTitle: "Organization Form",
      },
    },

    {
      path: "/contactuslist",
      name: "contactuslist",
      component: () =>
        import("@/views/pages/SubscribeContactUs/ContactUsSubscribe.vue"),
      meta: {
        pageTitle: "Contact US List",
      },
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
