const TOKEN_KEY = "access_token";
const USER_PROFILE_KEY = "user_profile";
const COUNTRY = "country";
const LANGUAGE = "language";
const CITY = "city";
const PWA = "IS_PWA";
const USER_PERMISSION = "userData";

const TokenService = {
  getToken() {
    return sessionStorage.getItem(TOKEN_KEY);
  },

  saveToken(accessToken) {
    sessionStorage.setItem(TOKEN_KEY, accessToken);
  },

  removeToken() {
    sessionStorage.removeItem(TOKEN_KEY);
  },
};

const UserService = {
  getUserPermissions() {
    let permisionStr = sessionStorage.getItem(USER_PERMISSION);
    if (permisionStr) {
      return JSON.parse(permisionStr);
    } else {
      return [];
    }
  },
  getUserProfile() {
    return sessionStorage.getItem(USER_PROFILE_KEY);
  },

  saveUserProfile(userProfile) {
    sessionStorage.setItem(USER_PROFILE_KEY, userProfile);
  },

  removeUserProfile() {
    sessionStorage.removeItem(USER_PROFILE_KEY);
  },
};

const LanguageService = {
  getLanguage() {
    return sessionStorage.getItem(LANGUAGE);
  },

  saveLanguage(language) {
    sessionStorage.setItem(LANGUAGE, language);
  },

  removeLanguage() {
    sessionStorage.removeItem(LANGUAGE);
  },
};

// const CountryService = {
//   getCountry() {
//     return sessionStorage.getItem(COUNTRY) ? JSON.parse(sessionStorage.getItem(COUNTRY)) : null;
//   },

//   saveCountry(country) {
//     sessionStorage.setItem(COUNTRY, JSON.stringify(country))
//   },

//   removeCountry() {
//     sessionStorage.removeItem(COUNTRY)
//   }
// }

// const CityService = {
//   getCity() {
//     return JSON.parse(sessionStorage.getItem(CITY) || null);
//   },

//   saveCity(city) {
//     sessionStorage.setItem(CITY, JSON.stringify(city));
//   },

//   removeCity() {
//     sessionStorage.removeItem(CITY);
//   },
// };

// const PwaService = {
//   getIsPwa() {
//     return sessionStorage.getItem(PWA);
//   },
//   setIsPwa(val) {
//     sessionStorage.setItem(PWA, val);
//   },
//   removeIsPwa() {
//     sessionStorage.removeItem(PWA);
//   },
// };

export {
  TokenService,
  UserService,
  LanguageService,
  // CountryService,
  // CityService,
  // PwaService,
};
